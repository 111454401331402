/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Tabs, Tab, Col, Row } from 'react-bootstrap';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const AFKGuidesShell: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>AFK Journey Crazed Shellbrute</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_brute.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Crazed Shellbrute</h1>
          <h2>
            Primal Lord Crazed Shellbrute guide that gives you the best teams to
            use against the boss.
          </h2>
          <p>
            Last updated: <strong>29/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Crazed Shellbrute" />
        <StaticImage
          src="../../../images/afk/generic/brute.webp"
          alt="Guides"
        />
        <p>
          <strong>Crazed Shellbrute</strong> is the second Primal Lord we
          encountered in the Season 2 of AFK Journey. In this guide you will
          find the best teams to use against him!
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage.{' '}
          <strong className="gold">
            Try running Dual carry, as currently there is no way to boost a
            Hypercarry’s damage high enough to make it worth running only one
          </strong>
          . Keep faction bonus in mind but don’t run suboptimal characters just
          to force it.
        </p>
        <p>
          Still, below you can find a few example teams. Big thanks to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Best teams" />
        <p>Here's a short tl;dr about which team should you use:</p>
        <ul className="bigger-margin">
          <li>
            If you have{' '}
            <AFKCharacter mode="inline" slug="phraesto" enablePopover /> at M
            and <AFKCharacter mode="inline" slug="mikola" enablePopover /> at S+
            use <strong>Team #1</strong>,
          </li>
          <li>
            If you have{' '}
            <AFKCharacter mode="inline" slug="phraesto" enablePopover /> at M
            and <AFKCharacter mode="inline" slug="mikola" enablePopover /> below
            S+ use <strong>Team #2,</strong> but to make it work well you need
            Tier 3 GB talent unlocked,
          </li>
          <li>
            If you have{' '}
            <AFKCharacter mode="inline" slug="phraesto" enablePopover /> below M
            and your Resonance is above 700, use <strong>Team #3</strong>,
          </li>
          <li>
            If you have{' '}
            <AFKCharacter mode="inline" slug="phraesto" enablePopover /> below M
            and your Resonance is below 700, use <strong>Team #4</strong>.
          </li>
        </ul>
        <p>
          If any of the setups below doesn't work for you, check{' '}
          <a
            href="https://docs.google.com/document/d/1mdbh3BRdFFWEMqTqqaROYKJrKQH4HW47pWOi4ohPDkI/edit?tab=t.0#heading=h.gn9v6lcqdqr"
            target="_blank"
            rel="noreferrer"
          >
            this document
          </a>{' '}
          for even more teams! Credits to <strong>AFK Analytica</strong> for
          gathering them!
        </p>
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season-n"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Best Team</>}
            className="with-bg"
          >
            <h5>Team #1 - Best Team</h5>
            <StaticImage
              src="../../../images/afk/dream_s/brute_1.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Puzzle & D3R4G0N</strong> from AFK Analytica
              Discord. This team requires investment into the{' '}
              <AFKItem name="Maulers" /> Talent tree.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover /> he
                is the main damage dealer of the team. Needs to be at{' '}
                <strong className="mythic">Mythic+</strong> to work in this team
                in this role,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="shakir" enablePopover /> is
                the a secondary carry and buffer. Needs to be at{' '}
                <strong className="mythic">Mythic</strong> to work in this team
                in this role,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="kruger" enablePopover /> is
                the sacrifice and debuffer,
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                is the healer, buffer and damage dealer once you have his EX,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> is a
                healer and sacrifice.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Resonating Spell" /> is the Spell of choice.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team2"
            title={<>Team #2 - GB Team</>}
            className="with-bg"
          >
            <h5>Team #2 - GB Team</h5>
            <StaticImage
              src="../../../images/afk/dream_s/brute_4.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Jigsaw</strong> from AFK Analytica Discord.
              This team requires investment into the{' '}
              <AFKItem name="Graveborn" /> Talent tree - up to tier 3 unique
              talent.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover /> he
                is the main damage dealer of the team. Needs to be at{' '}
                <strong className="mythic">Mythic</strong> to work,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> is
                the a sacrifice and debuffer,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="ludovic" enablePopover /> is
                the healer. Needs to be at{' '}
                <strong className="mythic">Mythic+</strong> to work,
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                is the healer, buffer and damage dealer once you have his EX,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="niru" enablePopover /> is a
                healer and sacrifice.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Resonating Spell" /> is the Spell of choice.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team3"
            title={<>Team #3 - Wilder Team</>}
            className="with-bg"
          >
            <h5>Team #3 - Wilder Team</h5>
            <StaticImage
              src="../../../images/afk/dream_s/brute_2.webp"
              alt="Guides"
            />
            <p>
              Credits to AFK Analytica Discord for testing the team. This team
              requires investment into the <AFKItem name="Wilders" /> Talent
              tree.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> is
                the main carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="bryon" enablePopover /> is the
                secondary carry and debuffer - he already works at 1 copy in
                this team,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> is
                the buffer and debuffer. Needs to be at{' '}
                <strong className="mythic">Mythic+</strong>,
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                is the healer, buffer and damage dealer once you have his EX,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="eironn" enablePopover /> is
                there for additional MDEF Shred and to activate the Wilder
                talent.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Resonating Spell" /> is the Spell of choice.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team4"
            title={<>Team #4 - LB Team</>}
            className="with-bg"
          >
            <h5>Team #4 - LB Team</h5>
            <StaticImage
              src="../../../images/afk/dream_s/brute_3.webp"
              alt="Guides"
            />
            <p>
              Credits to AFK Analytica Discord for testing the team. This team
              requires investment into the <AFKItem name="Lightbearers" />{' '}
              Talent tree.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="korin" enablePopover /> is the
                main carry. Needs to be at{' '}
                <strong className="mythic">Mythic+</strong> to work in this team
                in this role,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="temesia" enablePopover /> is
                the secondary carry,
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="sinbad" enablePopover /> can
                  be used instead,
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover /> is
                both the sacrifice to be eaten by the boss but also a damage
                dealer at higher dupes. He already works at 1 copy to fulfill
                his sacrifice role,
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                is the healer, buffer and damage dealer once you have his EX,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="rowan" enablePopover /> is the
                energy battery bot.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Resonating Spell" /> is the Spell of choice.
                </li>
              </ul>
            </ul>
          </Tab>
        </Tabs>
        <SectionHeader title="Videos" />
        <p>For more team ideas, check this video:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="L4-u_z93n0Q" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesShell;

export const Head: React.FC = () => (
  <Seo
    title="Crazed Shellbrute | AFK Journey | Prydwen Institute"
    description="Primal Lord Crazed Shellbrute guide that gives you the best teams to use against the boss."
    game="afk"
  />
);
